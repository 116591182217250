import { graphql, Link } from 'gatsby';
import {
  VictoryPie,
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryBar,
  VictoryLabel,
} from 'victory';
import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import Map from '../components/Map';
import { colors } from '../styles/colors';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';
import { Cultivation } from '../components/card-images/Cultivation';
import { Manufacturing } from '../components/card-images/Manufacturing';
import { Retail } from '../components/card-images/Retail';
import { Newspaper } from '../components/card-images/Newspaper';
import styled from '@emotion/styled';
import Img from 'gatsby-image';

const RecentPostThumbnail = styled.div`
  width: 30px;
  height: 30px;
  margin-top: 8px;
`;

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
    font: 1em 'Oswald', latin;
  }

  .leaflet-container {
    height: 100%;
    width: 200vw;
    @media (max-width: 800px) {
      width: 100%;
    }
  }

  @media (max-width: 800px) {
    .desktop-cards {
      display: none;
    }
  }
  @media (min-width: 800px) {
    .mobile-cards {
      display: none;
    }
  }
`;

const Chart = css``;

const RecentPosts = css`
  @media (max-width: 800px) {
    padding: 0;
    flex: 1;
    margin-right: 8px;
    margin-top: 12px;
  }
  @media (min-width: 800px) {
    flex: 1;
  }
`;

const Divider = css`
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid #ccc;
  margin: 0 0 8px 0;
  padding: 0;
  border-color: ${colors.massCann.lightGreen};
`;

const ValueCardStyle = css`
  background-color: ${colors.massCann.lightGray};
  margin-top: 0;
  margin-left: 8px;
  margin-bottom: 8px;
  .value-number {
    font-size: 5em;
    text-align: center;
    padding: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .value-number-no-padding {
    font-size: 5em;
    text-align: center;
  }
  .value-title {
    font-weight: 500;
    font-size: 1.2em;
    margin-top: 8px;
    text-align: center;
    color: ${colors.massCann.lightGreen};
  }
  @media (max-width: 800px) {
    margin-left: 4px;
    .value-number {
      font-size: 5em;
      text-align: center;
      padding: 8px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
`;

const RecentPostsCard = ({ posts, IComponent, mobile = false, height = '32%' }: any) => {
  // TODO - figure out how to align RecentPosts (marginLeft on style fix)
  return (
    <div
      style={{
        height,
        paddingTop: 4,
        backgroundColor: colors.massCann.lightGray,
        marginBottom: 10,
        marginLeft: mobile ? 4 : 16,
        width: '100%',
      }}
    >
      <div style={{ marginTop: 8, marginBottom: 8 }}>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              verticalAlign: 'center',
              marginRight: 8,
              marginBottom: 4,
              marginLeft: 12,
              paddingRight: 4,
            }}
          >
            {IComponent}
          </div>
          <h3 style={{ margin: 0, textTransform: 'uppercase', lineHeight: '2em' }}>Recent Posts</h3>
        </div>
        <hr css={Divider} />
      </div>
      <div style={{ width: '100%', marginTop: -5 }}>
        {posts.map(({ frontmatter, fields }: any) => {
          const {
            tags: [tag],
          } = frontmatter;
          return (
            <div
              key={frontmatter.title}
              style={{
                justifyContent: 'flex-start',
                display: 'flex',
                marginTop: 12,
                marginLeft: 12,
              }}
            >
              <RecentPostThumbnail>
                <Img
                  alt={`${frontmatter.title} cover image`}
                  style={{ height: '100%' }}
                  fluid={frontmatter.image.childImageSharp.fluid}
                />
              </RecentPostThumbnail>
              <div style={{ marginLeft: 4, paddingLeft: 4, fontSize: '1.5rem' }}>
                <div style={{ color: colors.massCann.lightGreen }}>{tag || 'General'}</div>
                <Link to={fields.slug}>
                  <div style={{ color: colors.massCann.darkGreen, marginBottom: -5 }}>
                    {frontmatter.title}
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Card = ({ title, dataPairs, IComponent, mobile = false, height = '32%' }: any) => {
  // TODO - figure out how to align RecentPosts (marginLeft on style fix)
  const [opPair, ...rest] = dataPairs;

  return (
    <div
      style={{
        height,
        paddingTop: 4,
        backgroundColor: colors.massCann.lightGray,
        marginBottom: 10,
        marginLeft: mobile ? 4 : 16,
        width: '100%',
      }}
    >
      <div style={{ marginTop: 8, marginBottom: 8 }}>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              verticalAlign: 'center',
              marginRight: 8,
              marginBottom: 4,
              marginLeft: 12,
              paddingRight: 4,
            }}
          >
            {IComponent}
          </div>
          <h3 style={{ margin: 0, textTransform: 'uppercase', lineHeight: '2em' }}>{title}</h3>
        </div>
        <hr css={Divider} />
      </div>
      <div
        style={{ paddingBottom: 8, display: 'flex', justifyContent: 'center', marginTop: '1.8rem' }}
      >
        <div>
          <div
            className="value-number-no-padding"
            style={{
              fontSize: '3em',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              margin: 'auto',
              justifyContent: 'center',
              lineHeight: '100%',
            }}
          >
            {opPair[1]}
          </div>
          <div
            className="value-title"
            style={{ textAlign: 'center', paddingTop: '0.3rem', color: colors.massCann.lightGreen }}
          >
            {opPair[0]}
          </div>
        </div>
        <div style={{ width: '45%', marginTop: -5 }}>
          {rest.map(([pairTitle, value]: any) => {
            return (
              <div style={{ marginLeft: 4, paddingLeft: 4, textAlign: 'center' }} key={pairTitle}>
                <div style={{ color: colors.massCann.lightGreen, marginBottom: -5 }}>
                  {pairTitle}:{' '}
                </div>
                <div style={{ marginTop: 0 }}>{value}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const ValueCard = ({ title, value, mobile = false }: any) => {
  const length = String(value).length;
  let fontSize = length < 3 ? '3.5em' : '2.6em';
  if (length >= 9) {
    fontSize = '2em';
  }

  const mobileStyles = mobile ? { width: '100%' } : { width: '20%' };

  return (
    <div css={ValueCardStyle} style={mobileStyles}>
      <div
        className="value-number"
        style={{
          fontSize,
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          margin: 'auto',
          justifyContent: 'center',
          height: '65%',
        }}
      >
        {value}
      </div>
      <div className="value-title" style={{ paddingBottom: 12 }}>
        {title}
      </div>
    </div>
  );
};

const Data: React.FunctionComponent = ({ data }: any) => {
  if (typeof window == 'undefined') {
    return null;
  }

  const dashboardData = data.allDashboardCsv.edges.map(n => n.node);
  const retailDataPairs = dashboardData
    .filter(n => n.Category === 'Retail')
    .map(n => [n.Title, n.Data]);

  const cultivationDataPairs = dashboardData
    .filter(n => n.Category === 'Cultivation')
    .map(n => [n.Title, n.Data]);

  const manufacturingDataPairs = dashboardData
    .filter(n => n.Category === 'Manufacturing')
    .map(n => [n.Title, n.Data]);

  const weeklyCards = dashboardData
    .filter(n => n.Category === 'Weekly')
    .map(n => {
      return [n.Data, n.Title];
    });
  
  const lastmonthCards = dashboardData
    .filter(n => n.Category === 'lastmonth')
    .map(n => {
      return [n.Data, n.Title];
    });

  const yearlyCards = dashboardData
    .filter(n => n.Category === 'ytd')
    .map(n => {
      return [n.Data, n.Title];
    });

  const markers = data.allMarkersCsv.edges.map(n => n.node);
  const salesData = data.allSaleschartCsv.edges
    .map(n => n.node)
    .map(p => {
      return {
        x: new Date(p.Date),
        y: Number(p.Sales.replace('$', '').replace(new RegExp(',', 'g'), '')) / 1e6,
      };
    });
  const dispensaryData = data.allSaleschartCsv.edges
    .map(n => n.node)
    .map(p => {
      return {
        x: new Date(p.Date),
        y: Number(p.Dispensaries),
      };
    });

  const pieData = data.allVerticalintchartCsv.edges
    .map(n => n.node)
    .map(p => {
      return {
        x: p.Vertical_Integration,
        y: p.Count,
      };
    });

  const pieCorporateData = data.allCorporationschartCsv.edges
    .map(n => n.node)
    .map(p => {
      return {
        x: p.Entity_Types,
        y: p.Count,
      };
    });

  const postData = data.allMarkdownRemark.edges.map(n => n.node).reverse();

  return (
    <IndexLayout>
      <Helmet>
        <title>Data</title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header css={[outer, SiteHeader]}>
          <div css={inner}>
            <SiteNav />
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <div className="desktop-cards">
            <div style={{ display: 'flex', marginTop: 15, height: 500 }}>
              <Map markers={markers} />
              <div style={{ width: '100%' }}>
                <Card title={'Retail'} dataPairs={retailDataPairs} IComponent={<Retail />} />
                <Card
                  title={'Cultivation'}
                  dataPairs={cultivationDataPairs}
                  IComponent={<Cultivation />}
                />
                <Card
                  title={'Manufacturing'}
                  dataPairs={manufacturingDataPairs}
                  IComponent={<Manufacturing />}
                />
              </div>
            </div>
          </div>
          <div className="mobile-cards" style={{ marginTop: 15 }}>
            <div style={{ height: 325, marginBottom: 15, marginLeft: 8 }}>
              <Map markers={markers} mobile />
            </div>
            <div>
              <Card title={'Retail'} dataPairs={retailDataPairs} IComponent={<Retail />} mobile />
              <Card
                title={'Cultivation'}
                dataPairs={cultivationDataPairs}
                IComponent={<Cultivation />}
                mobile
              />
              <Card
                title={'Manufacturing'}
                dataPairs={manufacturingDataPairs}
                IComponent={<Manufacturing />}
                mobile
              />
            </div>
          </div>
          <div style={{ marginTop: 20, display: 'flex', justifyContent: 'flex-start' }}>
            <div css={Chart}>
              <VictoryChart
                theme={VictoryTheme.material}
                scale={{ x: 'time' }}
                domain={{ y: [0, 11] }}
              >
                <VictoryLabel x={25} y={24} text="Weekly Sales($mm)" />
                <VictoryLine
                  style={{
                    data: { stroke: colors.massCann.midGreen },
                    parent: { border: '1px solid #ccc' },
                  }}
                  data={salesData}
                />
              </VictoryChart>
            </div>
            <div css={Chart}>
              <VictoryChart theme={VictoryTheme.material} scale={{ x: 'time' }}>
                <VictoryLabel x={25} y={24} text="Total Dispensaries" />
                <VictoryBar
                  style={{
                    data: { stroke: colors.massCann.midGreen },
                    parent: { border: '1px solid #ccc' },
                  }}
                  data={dispensaryData}
                />
              </VictoryChart>
            </div>
            <div css={RecentPosts} className="desktop-cards">
              <RecentPostsCard
                posts={postData}
                dataPairs={retailDataPairs}
                IComponent={<Newspaper />}
                height={'100%'}
              />
            </div>
          </div>
          <div css={RecentPosts} className="mobile-cards">
            <RecentPostsCard
              mobile
              posts={postData}
              dataPairs={retailDataPairs}
              IComponent={<Newspaper />}
              height={'100%'}
            />
          </div>
          <div>
            <h3>Week 37 Updates</h3>
            <hr css={Divider} />
            <div className="desktop-cards">
              <div style={{ display: 'flex' }}>
                {weeklyCards.slice(0, 5).map(([num, title]) => {
                  return <ValueCard value={num} title={title} />;
                })}
              </div>
              <div style={{ display: 'flex' }}>
                {weeklyCards.slice(5, 10).map(([num, title]) => {
                  return <ValueCard value={num} title={title} />;
                })}
              </div>
            </div>
            <div className="mobile-cards">
              {weeklyCards.map(([num, title]) => {
                return <ValueCard mobile value={num} title={title} mobile />;
              })}
            </div>
          </div>
          <div>
            <h3>July Summary</h3>
            <hr css={Divider} />
            <div className="desktop-cards">
              <div style={{ display: 'flex' }}>
                {lastmonthCards.slice(0, 5).map(([num, title]) => {
                  return <ValueCard value={num} title={title} />;
                })}
              </div>
              <div style={{ display: 'flex' }}>
                {lastmonthCards.slice(5, 10).map(([num, title]) => {
                  return <ValueCard value={num} title={title} />;
                })}
              </div>
            </div>
            <div className="mobile-cards">
              {lastmonthCards.map(([num, title]) => {
                return <ValueCard mobile value={num} title={title} mobile />;
              })}
            </div>
          </div>
          <div>
            <h3>Year to Date</h3>
            <hr css={Divider} />
            <div className="desktop-cards">
              <div style={{ display: 'flex' }}>
                {yearlyCards.slice(0, 5).map(([num, title]) => {
                  return <ValueCard value={num} title={title} />;
                })}
              </div>
              <div style={{ display: 'flex' }}>
                {yearlyCards.slice(5, 10).map(([num, title]) => {
                  return <ValueCard value={num} title={title} />;
                })}
              </div>
            </div>
            <div className="mobile-cards">
              {yearlyCards.map(([num, title]) => {
                return <ValueCard mobile value={num} title={title} mobile />;
              })}
            </div>
          </div>
          <h3>Corporate Cannabis</h3>
          <hr css={Divider} />
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <div style={{ width: '50%', height: 300 }}>
              <VictoryPie style={{labels: {fontSize: 20}}} colorScale="green" innerRadius={100} data={pieData} labels={d => `${d.x} (${d.y})`} />
              <hr css={Divider} />
              <div style={{ textAlign: 'center' }}>
                <p>Vertical Integration</p>
              </div>
            </div>
            <div style={{ width: '50%', height: 300 }}>
              <VictoryPie
                style={{labels: {fontSize: 20}}}
                colorScale="warm"
                innerRadius={100}
                data={pieCorporateData}
                labels={d => `${d.x} (${d.y})`}
              />
              <hr css={Divider} />
              <div style={{ textAlign: 'center' }}>
                <p>Entity Types</p>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export const pageQuery = graphql`
  {
    allMarkersCsv {
      edges {
        node {
          Licensee
          Indoor_Outdoor
          License_Type
          Latitude
          Longitude
          License_Address
          Tier
          Current_Status
          Hours
        }
      }
    }
    allDashboardCsv {
      edges {
        node {
          Category
          Title
          Data
        }
      }
    }
    allSaleschartCsv {
      edges {
        node {
          Date
          Sales
          Dispensaries
        }
      }
    }
    allVerticalintchartCsv {
      edges {
        node {
          Vertical_Integration
          Count
        }
      }
    }
    allCorporationschartCsv {
      edges {
        node {
          Entity_Types
          Count
        }
      }
    }
    allMarkdownRemark(limit: 5) {
      edges {
        node {
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            date
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Data;
