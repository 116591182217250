import { Map, Marker, Popup, TileLayer, LayersControl, LayerGroup } from 'react-leaflet';
import React from 'react';

const zoomLevel = 8;
const center = [42.2522694, -71.9482346];

export const isType = (m, t, cs) => {
  return m.License_Type.includes(t) && m.Current_Status.includes(cs);
};

const MarkerPopup = ({ m }) => {
  if (m.License_Type === 'Cultivation') {
    return (
      <Popup>
        <div style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '0.3rem' }}>
          {m.Licensee}
        </div>
        <div>
          <span style={{ fontWeight: 'bold' }}>Cultivation Class</span>: {m.Tier}
        </div>
        <div>
          <span style={{ fontWeight: 'bold' }}>Status:</span> {m.Current_Status}
        </div>
      </Popup>
    );
  }

  if (m.License_Type === 'Retail') {
    return (
      <Popup>
        <div style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '0.3rem' }}>
          {m.Licensee}
        </div>
        <div>
          <span style={{ fontWeight: 'bold' }}>Hours:</span> {m.Hours || 'Unknown'}
        </div>
        <div>
          <span style={{ fontWeight: 'bold' }}>Status:</span> {m.Current_Status}
        </div>
      </Popup>
    );
  }

  // Manufacturing
  return (
    <Popup>
      <div style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '0.3rem' }}>
        {m.Licensee}
      </div>
      <div>
        <span style={{ fontWeight: 'bold' }}>Status:</span> {m.Current_Status}
      </div>
    </Popup>
  );
};

export default ({ markers, mobile }: any) => {
  const L = require('leaflet');

  const setBaseProps = (n: string) =>
    new L.Icon({
      iconUrl: require(`../content/img/markers/${n}`),
      // popupAnchor: new L.Point(30, 30), // Use if sizing moves pop-up off center
      iconSize: new L.Point(30, 30),
    });

  const CFinalIcon = setBaseProps('cultivation_final.png');
  const COpIcon = setBaseProps('cultivation_operational.png');
  const CProvIcon = setBaseProps('cultivation_provisional.png');

  const MFinalIcon = setBaseProps('manufacturing_final.png');
  const MOpIcon = setBaseProps('manufacturing_operational.png');
  const MProvIcon = setBaseProps('manufacturing_provisional.png');

  const RFinalIcon = setBaseProps('retail_final.png');
  const ROpIcon = setBaseProps('retail_operational.png');
  const RProvIcon = setBaseProps('retail_provisional.png');

  const MCLayerGroup = ({ type, currentStatus, icon }: any) => (
    <LayerGroup>
      {markers
        .filter(m => isType(m, type, currentStatus))
        .map(m => (
          <Marker icon={icon} position={[m.Latitude, m.Longitude]}>
            <MarkerPopup m={m} />
          </Marker>
        ))}
    </LayerGroup>
  );

  return (
    <Map center={center} zoom={mobile ? (zoomLevel - 1) :zoomLevel} scrollWheelZoom={false}>
      <LayersControl position="topright" name="OpenStreetMap.Mapnik">
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/*NOTE - It seems like you could component-ize this whole thing,*/}
        {/*but Gatsby throws addOverlay error*/}
        <LayersControl.Overlay checked name="Cultivators Operational">
          <MCLayerGroup
            type="Cultivation"
            currentStatus="Operational"
            icon={COpIcon}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Cultivators Final">
          <MCLayerGroup
            type="Cultivation"
            currentStatus="Final"
            icon={CFinalIcon}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Cultivators Provisional">
          <MCLayerGroup
            type="Cultivation"
            currentStatus="Provisional"
            icon={CProvIcon}
          />
        </LayersControl.Overlay>

        <LayersControl.Overlay checked name="Retailers Operational">
          <MCLayerGroup
            type="Retail"
            currentStatus="Operational"
            icon={ROpIcon}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Retailers Final">
          <MCLayerGroup
            type="Retail"
            currentStatus="Final"
            icon={RFinalIcon}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Retailers Provisional">
          <MCLayerGroup
            type="Retail"
            currentStatus="Provisional"
            icon={RProvIcon}
          />
        </LayersControl.Overlay>

        <LayersControl.Overlay checked name="Manufacturing Operational">
          <MCLayerGroup
            type="Manufacturer"
            currentStatus="Operational"
            icon={MOpIcon}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Manufacturing Final">
          <MCLayerGroup
            type="Manufacturer"
            currentStatus="Final"
            icon={MFinalIcon}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Manufacturing Provisional">
          <MCLayerGroup
            type="Manufacturer"
            currentStatus="Provisional"
            icon={MProvIcon}
          />
        </LayersControl.Overlay>
      </LayersControl>
    </Map>
  );
};
