import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';

/**
 * TODO - there has to be a better way to not duplicate code here.
 * Gatsby isn't letting me interpolate strings in queries.
 */

const newspaperQuery = graphql`
    query {
        file(relativePath: { eq: "img/newspaper.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fixed(width: 35, height: 35) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`;

export const Newspaper = ({ q }: any) => {
  return (
    <StaticQuery
      query={newspaperQuery}
      render={data => (
        <div>
          <img src={data.file.childImageSharp.fixed.src} />
        </div>
      )}
    />
  );
};
